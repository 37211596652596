import React, { useState } from 'react';
import axios from 'axios';
import './ComingSoonPage.css';

const ComingSoonPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Sending request with payload:', {
            email: email,
            attributes: { FIRSTNAME: name },
            listIds: [3],
            updateEnabled: true,
        });
    
        try {
            const response = await axios.post('https://api.brevo.com/v3/contacts', {
                email: email,
                attributes: { FIRSTNAME: name },
                listIds: [3],
                updateEnabled: true,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'api-key': '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', // Replace with your API key
                },
            });
            console.log('Response:', response.data);
            setMessage('Thank you! You have been successfully added to our list.');
        } catch (error) {
            console.error('Error response:', {
                status: error.response?.status,
                data: error.response?.data,
            });
            setMessage('Error: Unable to send your information. Please try again later.');
        }
    };
    
    

    return (
        <div className="coming-soon-page">
            <div className="content">
            <div className="logo">
                <img src={require('./assets/LOGO_FULL_WHITE.png')} alt="Logo" />
            </div>
                <div className='heading'><h1>Coming Soon</h1>
                <p>Sign up to get Early Access!</p></div>
                
                <form className="email-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Submit</button>
                </form>
                {message && <p className="message">{message}</p>}
                {/* <div className="social-icons">
                    <i className="fab fa-instagram"></i>
                    <i className="fab fa-youtube"></i>
                    <i className="fab fa-twitter"></i>
                    <i className="fab fa-dribbble"></i>
                </div> */}
            </div>
        </div>
    );
};

export default ComingSoonPage;
 